import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { PostDetailsTemplate } from "components/Templates/PostDetailsTemplate"

const Post = ({ data }) => {
  const { seo, featuredImage } = data.post

  data.post.ancestors = {
    nodes: [
      {
        title: "Insights",
        uri: "/insights",
      },
    ],
  }

  return (
    <Layout variant="post" pageContext={data.post}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />
      <PostDetailsTemplate
        data={{
          ...data.post,
        }}
      />
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      modified
      seo {
        title
        metaDesc
      }
      headerSettings {
        customPageTitle
      }
      content
      uri
      date
      categories {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`
